// Formulaire.scss
//.formulaire {
//  background: linear-gradient(90deg, #004aad, #cb6ce6);
//  min-height: 100vh;
//  color: white;
//  display: flex;
//  flex-direction: column;
//  align-items: center;
//  justify-content: center;
//  padding: 20px;
//}
.formulaire-title{

}
.formulaire-main{
  display: flex;
  max-width: 100%;
}
.titre-questionnaire {
  font-family: 'Bubblebody neue', cursive;
  font-size: 109px;
  color: #ffffff;
}
.conteneur-flex{
  display: flex;
}
.div-ratio, .div-image {
  width: 50%;
  aspect-ratio: 2.4 / 1;
  margin-left: 10%;
}

.div-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.start-button{
  background-color: #f6b737;
  width: 100%;
  border-radius:51px;

  &:hover{
    background-color: #f7bf50;
    transition: ease-in 300ms ;
    cursor: pointer;
    transform: scale(1.05);
  }
}